import React, { useState, useEffect, useCallback } from 'react';

const UTILITY_ALLOWANCES = {
  '0': 35,
  '1': 75,
  '2': 150,
  '3': 250,
  '4': 300,
  '5': 400
};

const BEDROOM_TYPES = ['0BR', '1BR', '2BR', '3BR', '4BR', '5BR'];

const defaultFormatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const IncomeStatement = ({ property, paymentStandards, formatCurrency = defaultFormatCurrency }) => {
  // Get default payment standard based on bedrooms per unit. If no bedrooms, assume 2BR for single family or 1BR for multifamily
  const getDefaultPaymentStandard = useCallback(() => {
    if (!property || !paymentStandards) return 1500;
    const units = property.number_of_units || 1;
    let bedroomsPerUnit;
  
    if (property.bedrooms) {
        bedroomsPerUnit = Math.floor(property.bedrooms / units);
    } else {
        bedroomsPerUnit = units === 1 ? 2 : 1;
    }
    const standardKey = `${bedroomsPerUnit}br`;
    return paymentStandards.standards?.[standardKey] || 1500;
  }, [property, paymentStandards]);

    const getInitialUnitDistribution = () => {
        const units = property?.number_of_units || 1;
        const distribution = {
        '0BR': 0, '1BR': 0, '2BR': 0, '3BR': 0, '4BR': 0, '5BR': 0
        };
    
        if (units === 1) {
        const bedrooms = property?.bedrooms || 2;
        distribution[`${bedrooms}BR`] = 1;
        } else if (property?.bedrooms) {
        // Calculate base bedrooms per unit
        const baseBedroomsPerUnit = Math.floor(property.bedrooms / units);
        let remainingBedrooms = property.bedrooms;
        
        // First assign all units to base bedroom count
        distribution[`${baseBedroomsPerUnit}BR`] = units;
        remainingBedrooms -= (baseBedroomsPerUnit * units);
        
        // Upgrade units one at a time until remaining bedrooms are used
        let currentUnit = 0;
        while (remainingBedrooms > 0 && currentUnit < units) {
            distribution[`${baseBedroomsPerUnit}BR`]--;
            distribution[`${baseBedroomsPerUnit + 1}BR`]++;
            remainingBedrooms--;
            currentUnit++;
        }
        } else {
        distribution['1BR'] = units;
        }
        
        return distribution;
    };

  // Use this in the initial state
    const [unitDistribution, setUnitDistribution] = useState(getInitialUnitDistribution());

    const [validationErrors, setValidationErrors] = useState([]);

    // Add validation check function
    const validateDistribution = useCallback((distribution) => {
        const errors = [];
        const totalUnits = Object.values(distribution).reduce((sum, count) => sum + count, 0);
        const totalBedrooms = Object.entries(distribution).reduce((sum, [type, count]) => 
            sum + (parseInt(type) * count), 0);
    
        if (property?.number_of_units && totalUnits !== property.number_of_units) {
            errors.push(`Total units (${totalUnits}) must equal property units (${property.number_of_units})`);
        }
    
        if (property?.bedrooms && totalBedrooms !== property.bedrooms) {
            errors.push(`Total bedrooms (${totalBedrooms}) must equal property bedrooms (${property.bedrooms})`);
        }
    
        setValidationErrors(errors);
    }, [property]);
    
    // Add validation check when distribution changes
    useEffect(() => {
        validateDistribution(unitDistribution);
    }, [unitDistribution, property, validateDistribution]);

    // Add helper function to calculate totals
    const calculateTotals = () => {
        return {
        units: BEDROOM_TYPES.reduce((sum, type) => sum + unitDistribution[type], 0),
        bedrooms: BEDROOM_TYPES.reduce((sum, type) => sum + (parseInt(type[0]) * unitDistribution[type]), 0),
        paymentStandard: BEDROOM_TYPES.reduce((sum, type) => 
            sum + (unitDistribution[type] * (paymentStandards?.standards?.[type.toLowerCase()] || 0)), 0),
        utilityAllowance: BEDROOM_TYPES.reduce((sum, type) => 
            sum + (unitDistribution[type] * (UTILITY_ALLOWANCES[type[0]] || 0)), 0),
        contractRent: BEDROOM_TYPES.reduce((sum, type) => 
            sum + (unitDistribution[type] * ((paymentStandards?.standards?.[type.toLowerCase()] || 0) - 
            (UTILITY_ALLOWANCES[type[0]] || 0))), 0)
        };
    };

  // State for editable values with proper defaults
  const [paymentStandard, setPaymentStandard] = useState(getDefaultPaymentStandard());
  const [vacancyRate, setVacancyRate] = useState(5);
  const [propertyManagementRate, setPropertyManagementRate] = useState(6);
  const [appraisedValue, setAppraisedValue] = useState(
    property?.market_value || 300000
  );
  const [insurancePerUnit, setInsurancePerUnit] = useState(1250);
  const [repairsPerUnit, setRepairsPerUnit] = useState(700);
  const [waterPerBedroom, setWaterPerBedroom] = useState(25);
  const [otherExpensesAnnual, setOtherExpensesAnnual] = useState(50);
  
  
  // Update values when property data changes
  useEffect(() => {
    if (property) {
      setPaymentStandard(getDefaultPaymentStandard());
      setAppraisedValue(property.market_value || 300000);
    }
  }, [property, getDefaultPaymentStandard]);

  // Use correct utility allowance based on bedrooms
  const utilityAllowance = UTILITY_ALLOWANCES[Math.min(5, property?.bedrooms || 2)];
  
  // Monthly calculations
  const calculateMonthly = () => {
    // Get totals including bedroom count from unit distribution
    const totals = calculateTotals();
    
    // Rent calculations
    const grossRent = totals.contractRent;
    const vacancyLoss = grossRent * (vacancyRate / 100);
    const netRent = grossRent - vacancyLoss;
    
    // Expense calculations
    const propertyTaxes = (appraisedValue * 0.013998) / 12;
    const insurance = (insurancePerUnit * totals.units) / 12;
    const propertyManagement = netRent * (propertyManagementRate / 100);
    const repairs = (repairsPerUnit * totals.units) / 12;
    const water = waterPerBedroom * totals.bedrooms;
    const refuse = (totals.units >= 2 ? 500 : 0) / 12;
    const electric = (totals.units >= 3 ? 30 : 0);
    const permits = (56 * totals.units) / 12;
    const otherExpenses = otherExpensesAnnual / 12;
    
    const totalExpenses = propertyTaxes + insurance + propertyManagement + 
      repairs + water + refuse + electric + permits + otherExpenses;
    
    const noi = netRent - totalExpenses;
    
    return {
      paymentStandard,
      utilityAllowance,
      grossRent,
      vacancyLoss,
      netRent,
      propertyTaxes,
      insurance,
      propertyManagement,
      repairs,
      water,
      refuse,
      electric,
      permits,
      otherExpenses,
      totalExpenses,
      noi
    };
  };

  const monthly = calculateMonthly();
  const annual = Object.entries(monthly).reduce((acc, [key, value]) => {
    acc[key] = value * 12;
    return acc;
  }, {});

  const renderEmptyCell = () => (
    <>
      <td className="py-2"></td>
      <td className="py-2"></td>
    </>
  );

  return (
    <div>
    {/* Revenue Assumptions Section */}
    <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Revenue Assumptions</h2>

        {validationErrors.length > 0 && (
        <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-lg mb-4">
            {validationErrors.map((error, index) => (
            <div key={index}>{error}</div>
            ))}
        </div>
        )}

        <div className="overflow-x-auto">
        <table className="w-full">
            <thead>
                <tr className="border-b">
                <th className="text-left py-2 font-semibold">Category</th>
                {BEDROOM_TYPES.map(type => (
                    <th key={type} className="text-center py-2 font-semibold w-24">{type}</th>
                ))}
                <th className="text-center py-2 font-semibold w-32">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr className="border-b">
                <td className="py-2">Units</td>
                {BEDROOM_TYPES.map(type => (
                    <td key={type} className="py-2">
                    <input
                        type="number"
                        value={unitDistribution[type]}
                        onChange={(e) => {
                        const newDist = {...unitDistribution, [type]: Number(e.target.value)};
                        setUnitDistribution(newDist);
                        }}
                        className="w-20 p-1 border rounded text-center mx-auto block"
                    />
                    </td>
                ))}
                <td className="py-2 text-center font-semibold">{calculateTotals().units}</td>
                </tr>
                <tr className="border-b">
                <td className="py-2">Payment Standard</td>
                {BEDROOM_TYPES.map(type => (
                    <td key={type} className="py-2 text-center">
                    {formatCurrency(paymentStandards?.standards?.[type.toLowerCase()] || 0)}
                    </td>
                ))}
                <td className="py-2 text-center font-semibold">{formatCurrency(calculateTotals().paymentStandard)}</td>
                </tr>
                <tr className="border-b text-red-600 border-b-2 border-gray-300">
                <td className="py-2">Less: Utility Allowance</td>
                {BEDROOM_TYPES.map(type => (
                    <td key={type} className="py-2 text-center">
                    ({formatCurrency(UTILITY_ALLOWANCES[type[0]] || 0)})
                    </td>
                ))}
                <td className="py-2 text-center font-semibold">({formatCurrency(calculateTotals().utilityAllowance)})</td>
                </tr>
                <tr className="border-b font-semibold">
                <td className="py-2">Contract Rent</td>
                {BEDROOM_TYPES.map(type => (
                    <td key={type} className="py-2 text-center">
                    {formatCurrency((paymentStandards?.standards?.[type.toLowerCase()] || 0) - 
                        (UTILITY_ALLOWANCES[type[0]] || 0))}
                    </td>
                ))}
                <td className="py-2 text-center">{formatCurrency(calculateTotals().contractRent)}</td>
                </tr>
            </tbody>
            </table>
        </div>
    </div>
    
    {/* Income Statement Section */}
    <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
      <h2 className="text-xl font-semibold mb-4">Income Statement</h2>
      <div className="overflow-x-auto">
        <table className="w-full">
        <thead>
                <tr className="border-b">
                    <th className="text-left py-2 font-semibold w-32"></th>
                    <th className="text-right py-2 font-semibold w-32">Monthly</th>
                    <th className="text-right py-2 font-semibold w-32">Annual</th>
                    <th className="text-right py-2 font-semibold w-32">Assumption</th>
                    <th className="text-left py-2 font-semibold pl-4 w-40">Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="border-b font-semibold">
                    <td className="py-2">Gross Rent</td>
                    <td className="py-2 text-right">{formatCurrency(monthly.grossRent)}</td>
                    <td className="py-2 text-right">{formatCurrency(annual.grossRent)}</td>
                    {renderEmptyCell()}
                    </tr>
                    <tr className="border-b text-red-600">
                    <td className="py-2">Less: Vacancy</td>
                    <td className="py-2 text-right">({formatCurrency(monthly.vacancyLoss)})</td>
                    <td className="py-2 text-right">({formatCurrency(annual.vacancyLoss)})</td>
                    <td className="py-2">
                        <input
                        type="number"
                        value={vacancyRate}
                        onChange={(e) => setVacancyRate(Number(e.target.value))}
                        className="w-24 p-1 border rounded text-right block ml-auto text-black"
                        />
                    </td>
                    <td className="py-2 pl-4 text-sm text-gray-600">% of Gross Rent</td>
                    </tr>
                    <tr className="border-b font-semibold">
                    <td className="py-2">Net Rent</td>
                    <td className="py-2 text-right">{formatCurrency(monthly.netRent)}</td>
                    <td className="py-2 text-right">{formatCurrency(annual.netRent)}</td>
                    {renderEmptyCell()}
                    </tr>
                    <tr className="border-b text-red-600">
                    <td className="py-2">Property Taxes</td>
                    <td className="py-2 text-right">({formatCurrency(monthly.propertyTaxes)})</td>
                    <td className="py-2 text-right">({formatCurrency(annual.propertyTaxes)})</td>
                    <td className="py-2">
                        <input
                        type="number"
                        value={appraisedValue}
                        onChange={(e) => setAppraisedValue(Number(e.target.value))}
                        className="w-24 p-1 border rounded text-right block ml-auto text-black"
                        />
                    </td>
                    <td className="py-2 pl-4 text-sm text-gray-600">Assessed Value</td>
                    </tr>
                    <tr className="border-b text-red-600">
                    <td className="py-2">Insurance</td>
                    <td className="py-2 text-right">({formatCurrency(monthly.insurance)})</td>
                    <td className="py-2 text-right">({formatCurrency(annual.insurance)})</td>
                    <td className="py-2">
                        <input
                        type="number"
                        value={insurancePerUnit}
                        onChange={(e) => setInsurancePerUnit(Number(e.target.value))}
                        className="w-24 p-1 border rounded text-right block ml-auto text-black"
                        />
                    </td>
                    <td className="py-2 pl-4 text-sm text-gray-600">Annually per Unit</td>
                    </tr>
                    <tr className="border-b text-red-600">
                    <td className="py-2">Property Management</td>
                    <td className="py-2 text-right">({formatCurrency(monthly.propertyManagement)})</td>
                    <td className="py-2 text-right">({formatCurrency(annual.propertyManagement)})</td>
                    <td className="py-2">
                        <input
                        type="number"
                        value={propertyManagementRate}
                        onChange={(e) => setPropertyManagementRate(Number(e.target.value))}
                        className="w-24 p-1 border rounded text-right block ml-auto text-black"
                        />
                    </td>
                    <td className="py-2 pl-4 text-sm text-gray-600">% of Net Rent</td>
                    </tr>
                    <tr className="border-b text-red-600">
                    <td className="py-2">Repairs & Maintenance</td>
                    <td className="py-2 text-right">({formatCurrency(monthly.repairs)})</td>
                    <td className="py-2 text-right">({formatCurrency(annual.repairs)})</td>
                    <td className="py-2">
                        <input
                        type="number"
                        value={repairsPerUnit}
                        onChange={(e) => setRepairsPerUnit(Number(e.target.value))}
                        className="w-24 p-1 border rounded text-right block ml-auto text-black"
                        />
                    </td>
                    <td className="py-2 pl-4 text-sm text-gray-600">Annually per Unit</td>
                    </tr>
                    <tr className="border-b text-red-600">
                    <td className="py-2">Water</td>
                    <td className="py-2 text-right">({formatCurrency(monthly.water)})</td>
                    <td className="py-2 text-right">({formatCurrency(annual.water)})</td>
                    <td className="py-2">
                        <input
                        type="number"
                        value={waterPerBedroom}
                        onChange={(e) => setWaterPerBedroom(Number(e.target.value))}
                        className="w-24 p-1 border rounded text-right block ml-auto text-black"
                        />
                    </td>
                    <td className="py-2 pl-4 text-sm text-gray-600">Monthly per Bedroom</td>
                    </tr>
                    <tr className="border-b text-red-600">
                    <td className="py-2">Refuse</td>
                    <td className="py-2 text-right">({formatCurrency(monthly.refuse)})</td>
                    <td className="py-2 text-right">({formatCurrency(annual.refuse)})</td>
                    {renderEmptyCell()}
                    </tr>
                    <tr className="border-b text-red-600">
                    <td className="py-2">Electric</td>
                    <td className="py-2 text-right">({formatCurrency(monthly.electric)})</td>
                    <td className="py-2 text-right">({formatCurrency(annual.electric)})</td>
                    {renderEmptyCell()}
                    </tr>
                    <tr className="border-b text-red-600">
                    <td className="py-2">Permits</td>
                    <td className="py-2 text-right">({formatCurrency(monthly.permits)})</td>
                    <td className="py-2 text-right">({formatCurrency(annual.permits)})</td>
                    {renderEmptyCell()}
                    </tr>
                    <tr className="border-b text-red-600">
                    <td className="py-2">Other</td>
                    <td className="py-2 text-right">({formatCurrency(monthly.otherExpenses)})</td>
                    <td className="py-2 text-right">({formatCurrency(annual.otherExpenses)})</td>
                    <td className="py-2">
                        <input
                        type="number"
                        value={otherExpensesAnnual}
                        onChange={(e) => setOtherExpensesAnnual(Number(e.target.value))}
                        className="w-24 p-1 border rounded text-right block ml-auto text-black"
                        />
                    </td>
                    <td className="py-2 pl-4 text-sm text-gray-600">Annually</td>
                    </tr>
                    <tr className="border-b font-semibold text-red-600">
                    <td className="py-2">Total Expenses</td>
                    <td className="py-2 text-right">({formatCurrency(monthly.totalExpenses)})</td>
                    <td className="py-2 text-right">({formatCurrency(annual.totalExpenses)})</td>
                    {renderEmptyCell()}
                    </tr>
                    <tr className="font-bold text-lg">
                    <td className="py-2">NOI</td>
                    <td className="py-2 text-right">{formatCurrency(monthly.noi)}</td>
                    <td className="py-2 text-right">{formatCurrency(annual.noi)}</td>
                    {renderEmptyCell()}
                    </tr>
                </tbody>
        </table>
      </div>
    </div>
  </div>            
  );
};

export default IncomeStatement;