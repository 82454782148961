import React, { useState, useCallback } from 'react';
import { fetchWithAuth } from '../App';
import DataTable from './DataTable';
import { AlertTriangle } from 'lucide-react';

// Add this custom cell renderer component
const ViolationCell = ({ value }) => {
  if (!value) return null;
  return <AlertTriangle className="h-5 w-5 text-red-500 mx-auto" />;
};

// Update this custom cell renderer for high water charges to show the amount
const HighWaterCell = ({ value }) => {
  if (!value) return null;
  return <span className="text-blue-500 font-medium">${value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>;
};

const PortfolioTable = ({ onPropertySelect }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAddresses, setSelectedAddresses] = useState([]);

  const processData = useCallback((rawData) => {
    if (!Array.isArray(rawData)) return [];
    
    return rawData
      .filter(row => row && typeof row === 'object' && Object.keys(row).length > 0)
      .map(row => {
        const processedRow = {};
        Object.entries(row).forEach(([key, value]) => {
          if (typeof value === 'string' && !isNaN(value.replace(/,/g, ''))) {
            processedRow[key] = parseFloat(value.replace(/,/g, ''));
          } else if (typeof value === 'number' && isNaN(value)) {
            processedRow[key] = null;
          } else if (value === 'NaN') {
            processedRow[key] = null;
          } else {
            processedRow[key] = value;
          }
        });
        return processedRow;
      });
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchWithAuth('/api/data/portfolio');
      const result = await response.json();
      
      const processedData = processData(result || []);
      setData(processedData);
    } catch (err) {
      console.error('Fetch data error:', err);
    } finally {
      setLoading(false);
    }
  }, [processData]);

  const handleRowSelect = (selectedIds) => {
    setSelectedAddresses(selectedIds);
  };

  const handlePropertySearch = () => {
    if (selectedAddresses.length === 1) {
      const selectedProperty = data.find(row => row.Property === selectedAddresses[0]);
      if (selectedProperty) {
        onPropertySelect(selectedProperty.Property);
      }
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    'Property',
    'Owner',
    'Units',
    'Occupancy',
    'TTM NOI',
    'Active Violations',
    'High Water Charges',
    'Property Taxes Outstanding',
    'Trash Fees Outstanding',
    'Total Taxes & Fees Outstanding'
  ];

  return (
    <div className="flex flex-col h-full w-full">
      <div className="bg-white rounded-lg shadow p-4 flex-grow flex flex-col">
        {selectedAddresses.length === 1 && (
          <div className="mb-4">
            <button
              onClick={handlePropertySearch}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
            >
              Property Search
            </button>
          </div>
        )}
        
        {loading ? (
          <div className="text-gray-500">Loading...</div>
        ) : data && data.length > 0 ? (
          <div className="flex-grow">
            <DataTable 
              data={data}
              visibleColumns={columns}
              columnOrder={columns}
              initialSort={[
                {
                  id: 'Active Violations',
                  desc: false
                }
              ]}
              primaryKey="Property"
              onRowSelect={handleRowSelect}
              customCells={{
                'Active Violations': ViolationCell,
                'High Water Charges': HighWaterCell
              }}
              columnLabels={{}}
            />
          </div>
        ) : (
          <div className="text-gray-500">No portfolio data available</div>
        )}
      </div>
    </div>
  );
};

export default PortfolioTable;
