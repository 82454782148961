import React, { useState, useEffect } from 'react';
import { fetchVacancies, updateVacancyStatus, updateVacancyNotes } from '../services/vacancyService';

const VacancyStatusGroups = {
  VACANT: "Vacant",
  RENOVATION: "Renovation",
  LEASING: "Leasing",
  PHA: "PHA"
};

const Vacancy = () => {
  const [vacancies, setVacancies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingNote, setEditingNote] = useState(null);
  const [noteText, setNoteText] = useState('');

  useEffect(() => {
    loadVacancies();
  }, []);

  const loadVacancies = async () => {
    try {
      setLoading(true);
      const data = await fetchVacancies();
      setVacancies(data);
      setError(null);
    } catch (err) {
      setError('Failed to load vacancies. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (id, newStatus) => {
    try {
      await updateVacancyStatus(id, newStatus);
      // Refresh the list
      loadVacancies();
    } catch (err) {
      setError('Failed to update status. Please try again.');
      console.error(err);
    }
  };

  const startEditingNote = (vacancy) => {
    setEditingNote(vacancy.id);
    setNoteText(vacancy.notes || '');
  };

  const cancelEditingNote = () => {
    setEditingNote(null);
    setNoteText('');
  };

  const saveNote = async (id) => {
    try {
      await updateVacancyNotes(id, noteText);
      setEditingNote(null);
      // Refresh the list
      loadVacancies();
    } catch (err) {
      setError('Failed to update notes. Please try again.');
      console.error(err);
    }
  };

  // Group vacancies by status
  const groupedVacancies = vacancies.reduce((acc, vacancy) => {
    const status = vacancy.status;
    if (!acc[status]) {
      acc[status] = [];
    }
    acc[status].push(vacancy);
    return acc;
  }, {});

  // Sort status groups in desired order
  const orderedStatuses = [
    VacancyStatusGroups.VACANT,
    VacancyStatusGroups.RENOVATION,
    VacancyStatusGroups.LEASING,
    VacancyStatusGroups.PHA
  ];

  if (loading) {
    return <div className="p-4">Loading vacancies...</div>;
  }

  if (error) {
    return (
      <div className="p-4 text-red-600">
        <p>{error}</p>
        <button
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={loadVacancies}
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="p-4">
            
      {vacancies.length === 0 ? (
        <p>No vacant units found.</p>
      ) : (
        <div>
          {orderedStatuses.map(status => {
            const statusVacancies = groupedVacancies[status] || [];
            if (statusVacancies.length === 0) return null;
            
            return (
              <div key={status} className="mb-8">
                <h2 className="text-xl font-semibold mb-3 border-b pb-2">{status} ({statusVacancies.length})</h2>
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="py-2 px-4 text-left">Property Address</th>
                        <th className="py-2 px-4 text-left">Bedrooms</th>
                        <th className="py-2 px-4 text-left">Status</th>
                        <th className="py-2 px-4 text-left">Notes</th>
                        <th className="py-2 px-4 text-left">Last Updated</th>
                      </tr>
                    </thead>
                    <tbody>
                      {statusVacancies.map(vacancy => (
                        <tr key={vacancy.id} className="border-b hover:bg-gray-50">
                          <td className="py-2 px-4">{vacancy.full_address}</td>
                          <td className="py-2 px-4">{vacancy.bedrooms}</td>
                          <td className="py-2 px-4">
                            <select
                              value={vacancy.status}
                              onChange={(e) => handleStatusChange(vacancy.id, e.target.value)}
                              className="border rounded py-1 px-2 w-full"
                            >
                              {Object.values(VacancyStatusGroups).map(statusOption => (
                                <option key={statusOption} value={statusOption}>
                                  {statusOption}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td className="py-2 px-4">
                            {editingNote === vacancy.id ? (
                              <div>
                                <textarea
                                  value={noteText}
                                  onChange={(e) => setNoteText(e.target.value)}
                                  className="border rounded py-1 px-2 w-full"
                                  rows="3"
                                />
                                <div className="mt-1 flex space-x-2">
                                  <button
                                    onClick={() => saveNote(vacancy.id)}
                                    className="px-3 py-1 bg-green-500 text-white text-sm rounded hover:bg-green-600"
                                  >
                                    Save
                                  </button>
                                  <button
                                    onClick={cancelEditingNote}
                                    className="px-3 py-1 bg-gray-300 text-gray-700 text-sm rounded hover:bg-gray-400"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="whitespace-pre-wrap">{vacancy.notes || 'No notes'}</div>
                                <button
                                  onClick={() => startEditingNote(vacancy)}
                                  className="mt-1 text-sm text-blue-600 hover:underline"
                                >
                                  Edit Notes
                                </button>
                              </div>
                            )}
                          </td>
                          <td className="py-2 px-4">
                            {new Date(vacancy.updated_at).toLocaleDateString()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Vacancy;