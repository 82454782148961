/**
 * Vacancy API Service
 * Handles all API calls related to vacancies
 */

// Import the fetchWithAuth function
import { fetchWithAuth } from '../App';

// API endpoint
const API_URL = '/api/vacancies';

/**
 * Fetch all vacancies
 * @returns {Promise<Array>} Array of vacancy objects
 */
export const fetchVacancies = async () => {
  try {
    const response = await fetchWithAuth(API_URL);
    
    // Let fetchWithAuth handle the 401 errors
    return await response.json();
  } catch (error) {
    console.error('Error fetching vacancies:', error);
    throw error;
  }
};

/**
 * Update vacancy status
 * @param {number} id - Vacancy ID
 * @param {string} status - New status value
 * @returns {Promise<Object>} Updated vacancy object
 */
export const updateVacancyStatus = async (id, status) => {
  try {
    const response = await fetchWithAuth(`${API_URL}/${id}/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status })
    });
    
    return await response.json();
  } catch (error) {
    console.error('Error updating vacancy status:', error);
    throw error;
  }
};

/**
 * Update vacancy notes
 * @param {number} id - Vacancy ID
 * @param {string} notes - New notes
 * @returns {Promise<Object>} Updated vacancy object
 */
export const updateVacancyNotes = async (id, notes) => {
  try {
    const response = await fetchWithAuth(`${API_URL}/${id}/notes`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ notes })
    });
    
    return await response.json();
  } catch (error) {
    console.error('Error updating vacancy notes:', error);
    throw error;
  }
}; 