import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import Login from './Login';
import BasicPropertyInfo from './components/BasicPropertyInfo';
import ViolationsTable from './components/ViolationsTable';
import ComparableProperties from './components/ComparableProperties';
import PriceAnalysis from './components/PriceAnalysis';
import PriceTrendChart from './components/PriceTrendChart';
import AssetModel from './components/AssetModel';
import DelinquencyTable from './components/DelinquencyTable';
import PortfolioTable from './components/PortfolioTable';
import Performance from './components/Performance';
import Vacancy from './components/Vacancy';

// Protected Route component
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};

// Fetch function with authentication for JWT token
export const fetchWithAuth = async (url, options = {}) => {
  const apiKey = localStorage.getItem('api_key');
  const response = await fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      'Authorization': `Bearer ${apiKey}`
    }
  });

  if (response.status === 401) {
    // Force logout
    localStorage.removeItem('api_key');
    localStorage.removeItem('is_authenticated');
    window.location.href = '/login';
    return;
  }

  // Check for new token in response headers
  const newToken = response.headers.get('New-Token');
  if (newToken) {
    localStorage.setItem('api_key', newToken);
  }

  return response;
};

// Main Property Search Component
function PropertySearch() {
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState({
    basic: false,
    comps: false,
    historical: false
  });
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    reference_property: null,
    payment_standards: null,
    violations: null,
    comparable_properties: null,
    statistics: null,
    historical_trend: null
  });
  const { logout } = useAuth();  // Get logout function from auth context
  const [mainView, setMainView] = useState('property-search');
  const [PropertyTab, setPropertyTab] = useState('property');
  const [portfolioTab, setPortfolioTab] = useState('overview');
  const [tenantTab, setTenantTab] = useState('delinquency');

  const fetchBasicInfo = async (searchValue) => {
    setLoading(prev => ({ ...prev, basic: true }));
    try {
      const response = await fetchWithAuth(`/api/property-basic?search=${encodeURIComponent(searchValue)}`);
      const result = await response.json();
      if (!response.ok) throw new Error(result.error);
      
      setData(prev => ({
        ...prev,
        reference_property: result.reference_property,
        payment_standards: result.payment_standards,
        violations: result.violations
      }));
  
      // Trigger loading of additional data
      fetchComps(result.opa_number, result.reference_property);
      fetchHistorical(result.opa_number, result.reference_property);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(prev => ({ ...prev, basic: false }));
    }
  };

  const fetchComps = async (opaNumber, referenceProperty) => {
    setLoading(prev => ({ ...prev, comps: true }));
    try {
      const response = await fetchWithAuth('/api/property-comps', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          opa_number: opaNumber,
          reference: referenceProperty
        })
      });
      const result = await response.json();
      if (!response.ok) throw new Error(result.error);
      
      setData(prev => ({
        ...prev,
        comparable_properties: result.comparable_properties,
        statistics: result.statistics
      }));
    } catch (err) {
      console.error('Error fetching comps:', err);
    } finally {
      setLoading(prev => ({ ...prev, comps: false }));
    }
  };

  const fetchHistorical = async (opaNumber, referenceProperty) => {
    setLoading(prev => ({ ...prev, historical: true }));
    try {
      const response = await fetchWithAuth('/api/property-historical', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          opa_number: opaNumber,
          reference: referenceProperty
        })
      });
      const result = await response.json();
      if (!response.ok) throw new Error(result.error);
      
      setData(prev => ({
        ...prev,
        historical_trend: result.historical_trend
      }));
    } catch (err) {
      console.error('Error fetching historical data:', err);
    } finally {
      setLoading(prev => ({ ...prev, historical: false }));
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchValue) return;
    
    setError(null);
    setData({
      reference_property: null,
      violations: null,
      comparable_properties: null,
      statistics: null,
      historical_trend: null
    });
    
    await fetchBasicInfo(searchValue);
  };

  const formatCurrency = (value) => {
    if (!value) return 'N/A';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatNumber = (value) => {
    if (!value) return 'N/A';
    return new Intl.NumberFormat('en-US').format(value);
  };

  // Add this function to handle portfolio property selection
  const handlePortfolioPropertySelect = (propertyAddress) => {
    setMainView('property-search');
    setSearchValue(propertyAddress);
    // Trigger the search
    fetchBasicInfo(propertyAddress);
  };

  return (
    <div className="container mx-auto px-4 py-8 w-full">
      <div className="flex justify-between items-center mb-8">
        <div className="flex gap-8 items-center">
          <div className="flex border rounded-lg overflow-hidden">
            <button
              className={`px-6 py-2 ${
                mainView === 'property-search' 
                  ? 'bg-blue-600 text-white' 
                  : 'bg-white text-gray-600 hover:bg-gray-50'
              }`}
              onClick={() => setMainView('property-search')}
            >
              Property
            </button>
            <button
              className={`px-6 py-2 ${
                mainView === 'portfolio' 
                  ? 'bg-blue-600 text-white' 
                  : 'bg-white text-gray-600 hover:bg-gray-50'
              }`}
              onClick={() => setMainView('portfolio')}
            >
              Portfolio
            </button>
            <button
              className={`px-6 py-2 ${
                mainView === 'delinquency' 
                  ? 'bg-blue-600 text-white' 
                  : 'bg-white text-gray-600 hover:bg-gray-50'
              }`}
              onClick={() => setMainView('delinquency')}
            >
              Tenants
            </button>
          </div>
        </div>
        <button
          onClick={logout}
          className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
        >
          Logout
        </button>
      </div>

      {mainView === 'property-search' ? (
        <>
          <form onSubmit={handleSearch} className="max-w-2xl mx-auto mb-8">
            <div className="flex gap-2">
              <input
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Enter address or OPA number to diligence property"
                className="flex-1 p-3 border rounded-lg"
              />
              <button
                type="submit"
                disabled={loading.basic}
                className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 disabled:opacity-50"
              >
                {loading.basic ? 'Searching...' : 'Search'}
              </button>
            </div>
          </form>

          {error && (
            <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-lg mb-8">
              {error}
            </div>
          )}

          <div className="flex border-b mb-8">
            <button
              className={`px-4 py-2 ${
                PropertyTab === 'property' 
                  ? 'border-b-2 border-blue-600 text-blue-600' 
                  : 'text-gray-600'
              }`}
              onClick={() => setPropertyTab('property')}
            >
              Property Information
            </button>
            <button
              className={`px-4 py-2 ${
                PropertyTab === 'underwriting' 
                  ? 'border-b-2 border-blue-600 text-blue-600' 
                  : 'text-gray-600'
              }`}
              onClick={() => setPropertyTab('underwriting')}
            >
              Underwriting
            </button>
          </div>

          {data.reference_property && (
            <>
              {PropertyTab === 'property' ? (
                <>
                  <BasicPropertyInfo 
                    property={data.reference_property} 
                    formatNumber={formatNumber}
                    formatCurrency={formatCurrency}
                  />
                  {data.violations?.length > 0 && (
                    <ViolationsTable violations={data.violations} />
                  )}
                  
                  {loading.comps ? (
                    <div className="animate-pulse mt-8">Loading comparable properties...</div>
                  ) : data.comparable_properties && (
                    <>
                      <PriceAnalysis stats={data.statistics} />
                      {loading.historical ? (
                        <div className="animate-pulse mt-8">Loading historical trends...</div>
                      ) : data.historical_trend && (
                        <PriceTrendChart historicalTrend={data.historical_trend} />
                      )}
                      <ComparableProperties 
                        comps={data.comparable_properties}
                        stats={data.statistics}
                        formatNumber={formatNumber}
                        formatCurrency={formatCurrency}
                      />
                    </>
                  )}
                </>
              ) : (
                <AssetModel 
                  property={data.reference_property}
                  paymentStandards={data.payment_standards}
                />
              )}
            </>
          )}
        </>
      ) : mainView === 'portfolio' ? (
        <div className="py-8 w-full">
          <div className="flex border-b mb-8">
            <button
              className={`px-4 py-2 ${
                portfolioTab === 'overview' 
                  ? 'border-b-2 border-blue-600 text-blue-600' 
                  : 'text-gray-600'
              }`}
              onClick={() => setPortfolioTab('overview')}
            >
              Overview
            </button>
            <button
              className={`px-4 py-2 ${
                portfolioTab === 'performance' 
                  ? 'border-b-2 border-blue-600 text-blue-600' 
                  : 'text-gray-600'
              }`}
              onClick={() => setPortfolioTab('performance')}
            >
              Performance
            </button>
          </div>
          
          {portfolioTab === 'overview' ? (
            <PortfolioTable onPropertySelect={handlePortfolioPropertySelect} />
          ) : (
            <Performance />
          )}
        </div>
      ) : (
        <div className="py-8 w-full">
          <div className="flex border-b mb-8">
            <button
              className={`px-4 py-2 ${
                tenantTab === 'delinquency' 
                  ? 'border-b-2 border-blue-600 text-blue-600' 
                  : 'text-gray-600'
              }`}
              onClick={() => setTenantTab('delinquency')}
            >
              Delinquency
            </button>
            <button
              className={`px-4 py-2 ${
                tenantTab === 'vacancy' 
                  ? 'border-b-2 border-blue-600 text-blue-600' 
                  : 'text-gray-600'
              }`}
              onClick={() => setTenantTab('vacancy')}
            >
              Vacancy
            </button>
          </div>
          
          {tenantTab === 'delinquency' ? (
            <DelinquencyTable />
          ) : (
            <Vacancy />
          )}
        </div>
      )}
    </div>
  );
}

// Main App component with routing
function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <PropertySearch />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;