import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { FormControl, InputLabel, MenuItem, Select, Box, Paper } from '@mui/material';
import { fetchWithAuth } from '../App';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Performance() {
  const [comparisonData, setComparisonData] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState('');
  const [owners, setOwners] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch comparison data
    setLoading(true);
    fetchWithAuth('/api/data/portfolio/portfolio-comparison')
      .then(response => response.json())
      .then(data => {
        setComparisonData(data);
        // Get unique owners from the data
        const uniqueOwners = [...new Set(data.map(item => item.group))];
        setOwners(uniqueOwners);
        if (uniqueOwners.length > 0) {
          setSelectedOwner(uniqueOwners[0]);
        }
      })
      .catch(error => console.error('Error fetching comparison data:', error))
      .finally(() => setLoading(false));
  }, []);

  // Filter data for selected owner
  const filteredData = comparisonData.filter(item => item.group === selectedOwner);

  const commonChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
        labels: {
          usePointStyle: true,
          padding: 20,
          font: { size: 12 }
        }
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `$${value.toLocaleString()}`,
          font: { size: 14 }
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.05)'
        }
      },
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          font: { size: 14 }
        },
        grid: {
          display: false
        }
      }
    }
  };

  const noiChartOptions = {
    ...commonChartOptions,
    plugins: {
      ...commonChartOptions.plugins,
      title: {
        display: true,
        text: 'NOI Comparison',
        font: {
          size: 16,
          weight: 'bold'
        },
        padding: {
          top: 10,
          bottom: 20
        }
      },
    }
  };

  const rentChartOptions = {
    ...commonChartOptions,
    plugins: {
      ...commonChartOptions.plugins,
      title: {
        display: true,
        text: 'Rent Comparison',
        font: {
          size: 16,
          weight: 'bold'
        },
        padding: {
          top: 10,
          bottom: 20
        }
      },
    }
  };

  const maintenanceChartOptions = {
    ...commonChartOptions,
    plugins: {
      ...commonChartOptions.plugins,
      title: {
        display: true,
        text: 'Annual Maintenance per Unit',
        font: {
          size: 16,
          weight: 'bold'
        },
        padding: {
          top: 10,
          bottom: 20
        }
      },
    }
  };

  const waterChartOptions = {
    ...commonChartOptions,
    plugins: {
      ...commonChartOptions.plugins,
      title: {
        display: true,
        text: 'Monthly Water per Unit',
        font: {
          size: 16,
          weight: 'bold'
        },
        padding: {
          top: 10,
          bottom: 20
        }
      },
    }
  };

  const getFormattedLabels = (data) => {
    return data.map(item => {
      const date = new Date(item.report_month);
      return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
    });
  };

  const noiChartData = {
    labels: getFormattedLabels(filteredData),
    datasets: [
      {
        label: 'Trailing NOI',
        data: filteredData.map(item => item.trailing_noi),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        fill: false,
        tension: 0.3,
        pointRadius: 3,
        pointHoverRadius: 5,
      },
      {
        label: 'Contract NOI',
        data: filteredData.map(item => item.contract_noi),
        borderColor: 'rgb(153, 102, 255)',
        backgroundColor: 'rgba(153, 102, 255, 0.5)',
        fill: false,
        tension: 0.3,
        pointRadius: 3,
        pointHoverRadius: 5,
      },
      {
        label: 'PHA NOI',
        data: filteredData.map(item => item.pha_noi),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        fill: false,
        tension: 0.3,
        pointRadius: 3,
        pointHoverRadius: 5,
      },
    ],
  };

  const rentChartData = {
    labels: getFormattedLabels(filteredData),
    datasets: [
      {
        label: 'Revenue',
        data: filteredData.map(item => item.monthly_revenue),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        fill: false,
        tension: 0.3,
        pointRadius: 3,
        pointHoverRadius: 5,
      },
      {
        label: 'Rent Roll',
        data: filteredData.map(item => item.rent_roll),
        borderColor: 'rgb(153, 102, 255)',
        backgroundColor: 'rgba(153, 102, 255, 0.5)',
        fill: false,
        tension: 0.3,
        pointRadius: 3,
        pointHoverRadius: 5,
      },
      {
        label: 'PHA Rent',
        data: filteredData.map(item => item.pha_rent),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        fill: false,
        tension: 0.3,
        pointRadius: 3,
        pointHoverRadius: 5,
      },
    ],
  };

  const maintenanceChartData = {
    labels: getFormattedLabels(filteredData),
    datasets: [
      {
        label: 'Maintenance per Unit',
        data: filteredData.map(item => item.maintenance_per_unit),
        borderColor: 'rgb(255, 206, 86)',
        backgroundColor: 'rgba(255, 206, 86, 0.5)',
        fill: false,
        tension: 0.3,
        pointRadius: 3,
        pointHoverRadius: 5,
      },
    ],
  };

  const waterChartData = {
    labels: getFormattedLabels(filteredData),
    datasets: [
      {
        label: 'Water per Unit',
        data: filteredData.map(item => item.water_per_unit),
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        fill: false,
        tension: 0.3,
        pointRadius: 3,
        pointHoverRadius: 5,
      },
    ],
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      height: 'calc(150vh - 150px)',
      gap: 2,
      p: 3 
    }}>
      <Paper elevation={2} sx={{ p: 3, height: '100%' }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3 
        }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="owner-select-label">Owner</InputLabel>
            <Select
              labelId="owner-select-label"
              value={selectedOwner}
              label="Owner"
              onChange={(e) => setSelectedOwner(e.target.value)}
              disabled={loading}
            >
              {owners.map((owner) => (
                <MenuItem key={owner} value={owner}>
                  {owner}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        
        <Box sx={{ 
          display: 'grid', 
          gridTemplateRows: '1fr 1fr 1fr 1fr',
          gap: 4,
          height: 'calc(100% - 80px)'
        }}>
          {loading ? (
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              height: '100%' 
            }}>
              Loading...
            </Box>
          ) : (
            <>
              <Box sx={{ width: '100%', height: '100%' }}>
                <Line data={noiChartData} options={noiChartOptions} />
              </Box>
              <Box sx={{ width: '100%', height: '100%' }}>
                <Line data={rentChartData} options={rentChartOptions} />
              </Box>
              <Box sx={{ width: '100%', height: '100%' }}>
                <Line data={maintenanceChartData} options={maintenanceChartOptions} />
              </Box>
              <Box sx={{ width: '100%', height: '100%' }}>
                <Line data={waterChartData} options={waterChartOptions} />
              </Box>              
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

export default Performance;
