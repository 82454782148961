import React, { useState, useEffect, useCallback } from 'react';
import { fetchWithAuth } from '../App';
import DataTable from './DataTable';

const DelinquencyTable = () => {
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10)); // Default to today in YYYY-MM-DD format
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sendingLetters, setSendingLetters] = useState(false);
  const [monthlyColumns, setMonthlyColumns] = useState([]);

  const formatMonthName = useCallback((yearMonth) => {
    if (!yearMonth) return '';
    const [year, month] = yearMonth.split('-');
    const date = new Date(parseInt(year), parseInt(month) - 1, 1);
    return date.toLocaleString('default', { month: 'long', year: 'numeric' });
  }, []);

  const formatCurrency = useCallback((value) => {
    if (value === null || value === undefined) return '-';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  }, []);

  const processData = useCallback((rawData) => {
    if (!Array.isArray(rawData)) return [];
    
    // Extract all unique month keys for the monthly balance columns
    const allMonths = new Set();
    rawData.forEach(row => {
      if (row.Monthly_Balances || row['Monthly Balances']) {
        const monthlyBalances = row.Monthly_Balances || row['Monthly Balances'];
        Object.keys(monthlyBalances).forEach(month => allMonths.add(month));
      }
    });
    
    // Sort months in reverse chronological order (most recent first)
    const sortedMonths = Array.from(allMonths).sort().reverse();
    
    // Update monthly columns state
    const monthColumns = sortedMonths.map(month => ({
      key: `Month_${month}`,
      label: formatMonthName(month)
    }));
    setMonthlyColumns(monthColumns);
    
    return rawData
      .filter(row => row && typeof row === 'object' && Object.keys(row).length > 0)
      .map(row => {
        const processedRow = {};
        Object.entries(row).forEach(([key, value]) => {
          if (key === 'Monthly Balances' && typeof value === 'object') {
            // Flatten the monthly balances into separate columns
            sortedMonths.forEach(month => {
              processedRow[`Month_${month}`] = value[month] || 0;
            });
          } else if (typeof value === 'string' && !isNaN(value.replace(/,/g, ''))) {
            processedRow[key] = parseFloat(value.replace(/,/g, ''));
          } else if (typeof value === 'number' && isNaN(value)) {
            processedRow[key] = null;
          } else if (value === 'NaN') {
            processedRow[key] = null;
          } else {
            processedRow[key] = value;
          }
        });
        return processedRow;
      });
  }, [formatMonthName]);

  const handleSendLetters = async () => {
    if (!selectedRows.length) return;

    try {
      setSendingLetters(true);
      
      // Extract addresses and tenant names from selected rows
      const addresses = selectedRows.map(row => row.Address);
      const tenant_names = selectedRows.map(row => row['Tenant Name']);
      
      const response = await fetchWithAuth('/api/delinquency-letters', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          addresses: addresses,
          tenant_names: tenant_names
        }),
      });
      
      if (response.ok) {
        // You might want to show a success message here
        setSelectedRows([]); // Clear selection after successful send
      } else {
        console.error('Failed to send letters');
        // You might want to show an error message here
      }
    } catch (error) {
      console.error('Error sending letters:', error);
    } finally {
      setSendingLetters(false);
    }
  };
  
  const fetchData = useCallback(async (date) => {
    try {
      setLoading(true);
      const requestUrl = `/api/data/delinquency${date ? `?date=${date}` : ''}`;
      const response = await fetchWithAuth(requestUrl);
      const result = await response.json();
      
      const processedData = processData(result.data || []);
      setData(processedData);
    } catch (err) {
      console.error('Fetch data error:', err);
    } finally {
      setLoading(false);
    }
  }, [processData]);

  const handleRowSelect = useCallback((selectedIds) => {
    // Find the corresponding rows in the data array for the selected IDs
    const selectedRowsData = selectedIds.map(id => 
      data.find(item => item.Address === id)
    ).filter(Boolean);
    
    setSelectedRows(selectedRowsData);
  }, [data]);

  useEffect(() => {
    fetchData(selectedDate);
  }, [selectedDate, fetchData]);

  // Create custom cells for currency formatting
  const customCells = useCallback(() => {
    const cells = {};
    
    // Add currency formatters for the monthly balance columns
    monthlyColumns.forEach(col => {
      cells[col.key] = ({ value }) => formatCurrency(value);
    });
    
    // Add currency formatters for the standard monetary columns
    ['Tenant Due', 'Subsidy Due', 'Tenant Rent Due', 'Tenant Rent Due 30+'].forEach(col => {
      cells[col] = ({ value }) => formatCurrency(value);
    });
    
    return cells;
  }, [monthlyColumns, formatCurrency]);

  return (
    <div className="flex flex-col h-full w-full">
      <div className="bg-white rounded-lg shadow p-4 flex-grow flex flex-col">
        <div className="mb-4 flex justify-between items-center">
          <input
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            className="p-2 border rounded-lg"
          />

          <button
            onClick={handleSendLetters}
            disabled={!selectedRows.length || sendingLetters}
            className={`px-4 py-2 rounded-lg ${
              selectedRows.length && !sendingLetters
                ? 'bg-blue-600 text-white hover:bg-blue-700'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            {sendingLetters ? 'Sending...' : 'Send Delinquency Letters'}
          </button>
        </div>
  
        {loading ? (
          <div className="text-gray-500">Loading...</div>
        ) : data && data.length > 0 ? (
          <div className="flex-grow">
            <DataTable 
              data={data}
              visibleColumns={[
                'Tenant Name',
                'Address',
                'Tenant Due',
                'Subsidy Due',
                'Tenant Rent Due',
                'Tenant Rent Due 30+',
                ...monthlyColumns.map(col => col.key)
              ]}
              columnOrder={[
                'Tenant Name',
                'Address',
                'Tenant Due',
                'Subsidy Due',
                'Tenant Rent Due',
                'Tenant Rent Due 30+',
                ...monthlyColumns.map(col => col.key)
              ]}
              columnLabels={
                monthlyColumns.reduce((acc, col) => {
                  acc[col.key] = col.label;
                  return acc;
                }, {})
              }
              initialFilters={{
                "Tenant Due": {
                  type: '>',
                  value: 0
                }
              }}
              initialSort={[
                {
                  id: 'Tenant Due',
                  desc: true
                }
              ]}
              onRowSelect={handleRowSelect}
              primaryKey="Address"
              customCells={customCells()}
            />
          </div>
        ) : (
          <div className="text-gray-500">No delinquency data available</div>
        )}
      </div>
    </div>
  );
};

export default DelinquencyTable;