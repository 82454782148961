import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useSortBy, useFilters, useRowSelect } from 'react-table';
import { ChevronUp, ChevronDown } from 'lucide-react';

function AdvancedColumnFilter({ column }) {
  const { filterValue, preFilteredRows, setFilter, id } = column;
  const [filterType, setFilterType] = useState('>=');
  const [inputValue, setInputValue] = useState('');
  const count = preFilteredRows.length;

  // Initialize filter type and value from filterValue if it exists
  useEffect(() => {
    if (filterValue && typeof filterValue === 'object') {
      setFilterType(filterValue.type);
      setInputValue(filterValue.value.toString());
    } else if (filterValue) {
      setInputValue(filterValue);
    }
  }, [filterValue]);

  const isNumber = useMemo(() => {
    const firstRow = preFilteredRows[0]?.values[id];
    return typeof firstRow === 'number';
  }, [preFilteredRows, id]);

  const handleFilterChange = (value) => {
    setInputValue(value);
    
    if (!value) {
      setFilter(undefined);
      return;
    }

    if (isNumber && filterType !== 'contains') {
      const numValue = parseFloat(value);
      if (isNaN(numValue)) return;

      setFilter({ type: filterType, value: numValue });
    } else {
      setFilter(value);
    }
  };

  const handleTypeChange = (newType) => {
    setFilterType(newType);
    if (inputValue) {
      if (isNumber && newType !== 'contains') {
        const numValue = parseFloat(inputValue);
        if (!isNaN(numValue)) {
          setFilter({ type: newType, value: numValue });
        }
      } else {
        setFilter(inputValue);
      }
    }
  };

  return (
    <div className="flex flex-col space-y-1 min-w-[120px]">
      {isNumber ? (
        <>
          <select
            value={filterType}
            onChange={e => handleTypeChange(e.target.value)}
            onClick={e => e.stopPropagation()}  // Prevent sorting when clicking select
            onFocus={e => e.stopPropagation()}  // Prevent sorting when focusing select
            className="w-full px-1 py-1 text-sm border rounded focus:outline-none focus:ring-1 focus:ring-blue-500"
          >
            <option value=">=">&gt;=</option>
            <option value=">">&gt;</option>
            <option value="<">&lt;</option>
            <option value="<=">&lt;=</option>
            <option value="=">=</option>
            <option value="contains">Contains</option>
          </select>
          <input
            value={inputValue}
            onChange={e => handleFilterChange(e.target.value)}
            onClick={e => e.stopPropagation()}  // Prevent sorting when clicking input
            onFocus={e => e.stopPropagation()}  // Prevent sorting when focusing input
            placeholder={`Filter ${count} records...`}
            className="w-full px-2 py-1 text-sm border rounded focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
        </>
      ) : (
        <input
          value={inputValue}
          onChange={e => handleFilterChange(e.target.value)}
          placeholder={`Search ${count} records...`}
          className="w-full px-2 py-1 text-sm border rounded focus:outline-none focus:ring-1 focus:ring-blue-500"
        />
      )}
    </div>
  );
}

function customFilterFunction(rows, id, filterValue) {
  if (!filterValue) return rows;

  return rows.filter(row => {
    const rowValue = row.values[id];
    
    // Handle null, undefined, or empty string values
    if (rowValue === null || rowValue === undefined || rowValue === '') {
      return false;
    }

    if (typeof filterValue === 'object') {
      const { type, value } = filterValue;
      switch (type) {
        case '>':
          return rowValue > value;
        case '>=':
          return rowValue >= value;
        case '<':
          return rowValue < value;
        case '<=':
          return rowValue <= value;
        case '=':
          return rowValue === value;
        default:
          return String(rowValue).toLowerCase().includes(String(value).toLowerCase());
      }
    }

    return String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase());
  });
}

function DataTable({ 
    data, 
    initialFilters = {}, 
    initialSort = [],     
    visibleColumns = [], 
    columnOrder = [],   
    onRowSelect,        
    primaryKey = 'id',
    customCells = {},
    columnLabels = {}  // Default to empty object
  }) {
    const [selectedRows, setSelectedRows] = useState(new Set());
  
    const columns = useMemo(() => {
      if (!data || data.length === 0) return [];
  
      // Start with the checkbox column
      let cols = [
        {
          id: 'selection',
          Header: ({ rows }) => {
            // Determine if every visible row is selected:
            const allVisibleSelected =
              rows.length > 0 &&
              rows.every(row => selectedRows.has(row.original[primaryKey]));
            
            return (
              <input
                type="checkbox"
                // Use our computed value for checked
                checked={allVisibleSelected}
                className="w-4 h-4 rounded border-gray-300"
                onChange={() => {
                  // Get all the visible row IDs
                  const visibleIds = rows.map(row => row.original[primaryKey]);
                  const newSelected = new Set(selectedRows);
                  if (allVisibleSelected) {
                    // If all visible rows are selected, deselect them
                    visibleIds.forEach(id => newSelected.delete(id));
                  } else {
                    // Otherwise, add (select) them all
                    visibleIds.forEach(id => newSelected.add(id));
                  }
                  setSelectedRows(newSelected);
                  if (onRowSelect) {
                    onRowSelect(Array.from(newSelected));
                  }
                }}
              />
            );
          },
          Cell: ({ row }) => (
            <input
              type="checkbox"
              checked={selectedRows.has(row.original[primaryKey])}
              className="w-4 h-4 rounded border-gray-300"
              onChange={(e) => {
                const newSelected = new Set(selectedRows);
                if (e.target.checked) {
                  newSelected.add(row.original[primaryKey]);
                } else {
                  newSelected.delete(row.original[primaryKey]);
                }
                setSelectedRows(newSelected);
                if (onRowSelect) {
                  onRowSelect(Array.from(newSelected));
                }
              }}
            />
          ),
          width: 40
        }
      ];
  
      // Get all available columns
      let availableColumns = Object.keys(data[0]).map(key => {
        const columnConfig = {
          Header: columnLabels[key] || key,  // Use custom label if available
          accessor: row => row[key],
          id: key,
          Filter: AdvancedColumnFilter,
          filter: customFilterFunction
        };

        // Only add custom Cell if it exists for this column
        if (customCells[key]) {
          columnConfig.Cell = customCells[key];
        }

        // Add custom sorting for boolean values
        const firstValue = data[0][key];
        if (typeof firstValue === 'boolean') {
          columnConfig.sortType = (rowA, rowB) => {
            const a = rowA.values[key];
            const b = rowB.values[key];
            if (a === b) return 0;
            return a ? -1 : 1;
          };
        }

        return columnConfig;
      });
  
      // Filter and order columns based on props
      if (visibleColumns.length > 0) {
        availableColumns = availableColumns.filter(col => 
          visibleColumns.includes(col.id)
        );
      }
  
      if (columnOrder.length > 0) {
        availableColumns.sort((a, b) => 
          columnOrder.indexOf(a.id) - columnOrder.indexOf(b.id)
        );
      }
  
      return [...cols, ...availableColumns];
    }, [data, visibleColumns, columnOrder, primaryKey, onRowSelect, customCells, columnLabels]);
  
    const defaultColumn = useMemo(
      () => ({
        Filter: AdvancedColumnFilter,
        filter: customFilterFunction,
      }),
      []
    );
  
    const initialFilterState = useMemo(() => 
      Object.entries(initialFilters).map(([id, value]) => ({
        id,
        value: typeof value === 'number' ? { type: '>=', value } : value
      }))
    , [initialFilters]);
  
    const tableInstance = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {
          filters: initialFilterState,
          sortBy: initialSort 
        }
      },
      useFilters,
      useSortBy,
      useRowSelect
    );
  
    const calculateTotals = (rows) => {
      const totals = {};
      
      if (rows.length > 0) {
        rows.forEach(row => {
          Object.entries(row.values).forEach(([key, value]) => {
            if (key === 'selection' || typeof value !== 'number' || isNaN(value)) {
              return;
            }
            totals[key] = Math.round((totals[key] || 0) + value);
          });
        });
      }
      
      return totals;
    };

    if (!data || data.length === 0) {
      return <div className="p-4 text-center text-gray-500">No data available</div>;
    }
  
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
    const totals = calculateTotals(rows);
  
    // Update the formatValue function to round numbers
    const formatValue = (value) => {
      if (typeof value === 'number' && !isNaN(value)) {
        return Math.round(value).toLocaleString();
      }
      return value;
    };

    // Add this to determine if a column is numeric
    const isNumericColumn = (columnId) => {
      if (columnId === 'selection') return false;
      const firstRow = rows[0]?.values[columnId];
      return typeof firstRow === 'number';
    };

    return (
        <div className="w-full h-full flex flex-col">
          <div className="overflow-x-auto rounded-lg shadow-md flex-grow">
            <table {...getTableProps()} className="w-full">
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-50">
                    {headerGroup.headers.map(column => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        className={`p-3 text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 ${
                          isNumericColumn(column.id) ? 'text-right' : 'text-left'
                        }`}
                        style={column.id === 'selection' ? { width: '40px' } : {}}
                      >
                        <div className="flex flex-col space-y-2">
                          <div className="flex items-center h-8">
                            <div className="flex-grow">
                              {column.id === 'selection' 
                                ? column.render('Header', { rows })  // Pass rows to Header
                                : column.render('Header')}
                            </div>
                            {column.id !== 'selection' && (
                              <div className="ml-2">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <ChevronDown className="w-4 h-4" />
                                  ) : (
                                    <ChevronUp className="w-4 h-4" />
                                  )
                                ) : null}
                              </div>
                            )}
                          </div>
                          {column.canFilter && column.id !== 'selection' && (
                            <div className="min-h-[72px]">
                              {column.render('Filter')}
                            </div>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <tr 
                      {...row.getRowProps()} 
                      className={`hover:bg-gray-50 ${selectedRows.has(row.original[primaryKey]) ? 'bg-blue-50' : ''}`}
                    >
                      {row.cells.map(cell => (
                        <td
                          {...cell.getCellProps()}
                          className={`px-3 py-2 text-sm text-gray-500 whitespace-nowrap ${
                            isNumericColumn(cell.column.id) ? 'text-right' : 'text-left'
                          }`}
                        >
                          {cell.column.id === 'selection' 
                            ? cell.render('Cell')
                            : cell.column.Cell 
                              ? cell.render('Cell')
                              : formatValue(cell.value)}
                        </td>
                      ))}
                    </tr>
                  );
                })}
                {/* Totals row */}
                <tr className="bg-gray-50 font-semibold">
                  {headerGroups[0].headers.map(column => (
                    <td
                      key={column.id}
                      className={`px-3 py-2 text-sm text-gray-700 whitespace-nowrap ${
                        isNumericColumn(column.id) ? 'text-right' : 'text-left'
                      }`}
                    >
                      {column.id === 'selection' ? (
                        'Totals'
                      ) : (
                        totals[column.id] !== undefined ? 
                          totals[column.id].toLocaleString() 
                          : ''
                      )}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
  }
  
  export default DataTable;